import {CartController} from '../components/cart/CartController';
import {CartStore} from '../domain/stores/CartStore';
import {OrderStore} from '../domain/stores/OrderStore';
import {StyleParam} from '../components/cart/constants';
import {ICart as ICartFromCartApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CartApi/types';
import {CashierExpressStore} from '../domain/stores/CashierExpressStore';
import {NavigationStore} from '../domain/stores/NavigationStore';
import {ISettingsParams} from '../components/cart/settingsParams';

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export type IControllerProps = {
  cartStore: Awaited<ReturnType<CartStore['toProps']>>;
  cashierExpressStore: Awaited<ReturnType<CashierExpressStore['toProps']>>;
  generalError: boolean;
  isLoading: boolean;
  isResponsive: boolean;
  navigationStore: Awaited<ReturnType<NavigationStore['toProps']>>;
  orderStore: Awaited<ReturnType<OrderStore['toProps']>>;
};

export type ICartControllerApi = {
  executeWithLoader: CartController['executeWithLoader'];
  load: CartController['load'];
  updateComponent: CartController['updateComponent'];
  reportFedopsInteraction: CartController['reportFedopsInteraction'];
  t: CartController['t'];
};

export type IMedialDimensions = {
  width: number;
  height: number;
};
export type ICartProductsManifest = Record<string, {href: string}>;

export enum ButtonSkins {
  BUTTON_SKIN_1 = 'button-skin-1',
  BUTTON_SKIN_2 = 'button-skin-2',
  BUTTON_SKIN_3 = 'button-skin-3',
  BUTTON_SKIN_4 = 'button-skin-4',
  BUTTON_SKIN_5 = 'button-skin-5',
  BUTTON_SKIN_6 = 'button-skin-6',
}

export type ICartStyleSettings = {
  booleans: {
    [StyleParam.ShowContinueShopping]: boolean;
    [StyleParam.ShowCoupon]: boolean;
    [StyleParam.ShowBuyerNote]: boolean;
    [StyleParam.ShowTax]: boolean;
    [StyleParam.ShowShipping]: boolean;
    [StyleParam.Responsive]: boolean;
  };
  fonts: {
    [StyleParam.CornerRadius]: {value: string; fontStyleParam: boolean};
    [StyleParam.SelectedSkin]: {value: ButtonSkins; fontStyleParam: boolean};
  };
};

export type ICart = ICartFromCartApi;

export type ICartItem = ICart['items'][0];

export type ICheckoutShippingRuleOption = ICart['shippingRuleInfo']['shippingRule']['options'];

export type ICartPublicData = Partial<{[k in keyof ISettingsParams]: string | {}}>;
